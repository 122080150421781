import type { FunctionComponent } from 'react'

import { Container } from '../../components/layout/grid'
import type { BentoBoxSectionFragment } from '../../graphql/fragments/BentoBoxSectionFragment.graphql'
import variables from '../../styles/variables.module.scss'
import styles from './bento-box.module.scss'
import { BentoOne } from './boxes/bento-one'
import { BentoThree } from './boxes/bento-three'
import { BentoThreeSquares } from './boxes/bento-three-squares'
import { BentoTwo } from './boxes/bento-two'

export const BentoBox: FunctionComponent<BentoBoxProps> = ({
    boxes,
    anchorId,
    spacingTop = 'gapXLarge',
    spacingBottom = 'gapXLarge',
}) => {
    return (
        <div
            style={{
                paddingTop: variables[spacingTop],
                paddingBottom: variables[spacingBottom],
            }}
        >
            <Container className={styles.container} id={anchorId}>
                {boxes?.items.map((box, index) => {
                    if ('type' in box) {
                        if (box.type === 'BentoOne')
                            return <BentoOne key={index} pieces={box.pieces} />
                        if (box.type === 'BentoTwo')
                            return <BentoTwo key={index} pieces={box.pieces} />
                        if (box.type === 'BentoThree')
                            return (
                                <BentoThree
                                    key={index}
                                    pieces={box.pieces}
                                    rectangleLeft={box.rectangleLeft}
                                />
                            )
                        if (box.type === 'BentoThreeSquares')
                            return (
                                <BentoThreeSquares
                                    key={index}
                                    pieces={box.pieces}
                                />
                            )
                    }

                    return null
                })}
            </Container>
        </div>
    )
}

export default BentoBox

export type BentoBoxProps = Omit<BentoBoxSectionFragment, 'sys' | 'type'> & {
    index?: number
}
